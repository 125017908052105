<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatapp" dense>
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          class="capitalize"
          style="padding: 14px 20px"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-toolbar dense flat style="margin-bottom: 5px">
        <v-toolbar-title>
          {{ _decode(salute) | capitalize }} {{ _decode(first) | capitalize }}
          {{ _decode(last) | capitalize }} Schedules
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn :to="{ name: 'patient.request' }" color="primary" icon>
          <v-icon>mdi-keyboard-return</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row>
        <v-col :sm="carSize" cols="12">
          <v-card :loading="loadevent" flat>
            <v-date-picker
              v-model="today"
              :allowed-dates="getAllowedDates"
              :events="arrayEvents"
              :min="isNow"
              event-color="#2196f3"
              full-width
              style="margin-top: 7px"
              @click:date="pickDate"
            ></v-date-picker>
          </v-card>
        </v-col>
        <v-col v-show="detail" cols="12" sm="6">
          <v-card outlined style="margin: 7px 0">
            <v-card-text>
              <div class="mylist">
                <ul>
                  <li>
                    <div class="myleft">Consulting Type</div>
                    <div class="myright">
                      {{ scheduleDetail.meeting_typesid | capitalize }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">Location</div>
                    <div class="myright">
                      {{ scheduleDetail.meeting_location | capitalize }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">Cost</div>
                    <div class="myright">
                      {{ currency(scheduleDetail.pricing) }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
          <v-card :loading="loading" flat loader-height="1">
            <v-card-text style="padding: 0px">
              <v-data-table
                :footer-props="{
                  'items-per-page-options': [10],
                }"
                :headers="headers"
                :items="scheduleList"
                :items-per-page="10"
                :sort-by="['schedule_time_start']"
                :sort-desc="[false]"
              >
                <template v-slot:item.schedule_time_start="{ item }">
                  {{ item.schedule_time_start }}
                </template>
                <template v-slot:item.schedule_time_end="{ item }">
                  {{ item.schedule_time_end }}
                </template>
                <template v-slot:item.closed="{ item }">
                  {{ item.available }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :disabled="checkStatus(item.closed, item.done)"
                        color="primary"
                        text
                        x-small
                        v-on="on"
                        @click.stop="$set(bookingDialog, item.id, true)"
                      >
                        {{ item.closed ? "closed" : "open" }}
                        <!-- <v-icon>
                          {{ item.closed ? "mdi-close" : "mdi-check" }}
                        </v-icon> -->
                      </v-btn>
                    </template>
                    <span>
                      {{
                        item.closed
                          ? "I am closed for appointments"
                          : "I am open and can make an appointment"
                      }}</span
                    >
                  </v-tooltip>
                  <ScheduleBooking
                    v-if="bookingDialog[item.id]"
                    :bookingDialog="bookingDialog[item.id]"
                    :first="first"
                    :last="last"
                    :myId="item.id"
                    :salute="salute"
                    @closeBook="closeBook"
                    @submitBook="submitBook"
                  />
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
import Utils from "../../mixins/utils";
import ScheduleBooking from "../../components/appointments/schedules/booking.vue";
// import moment from "moment";
export default {
  props: {
    id: { type: String },
    salute: { type: String },
    first: { type: String },
    last: { type: String },
  },
  components: { ScheduleBooking },
  mixins: [Utils, WinScroll("position")],
  data() {
    return {
      loading: false,
      bookingDialog: {},
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "patient.dash" },
        },
        {
          exact: true,
          link: true,
          text: "Request",
          to: { name: "patient.request" },
        },
        {
          exact: true,
          link: true,
          text: "Request det",
          to: { name: "patient.request.date" },
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      isNow: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      arrayEvents: [],
      carSize: 12,
      detail: false,
      scheduleList: [],
      scheduleDetail: {},
      headers: [
        { text: "start", value: "schedule_time_start" },
        { text: "end", value: "schedule_time_end" },
        { text: "available", value: "closed", align: "center" },
      ],
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.availableDays();
  },
  methods: {
    menulink() {
      this.$emit("sidebar");
    },
    getAllowedDates(val) {
      if (this.arrayEvents.indexOf(val) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    availableDays() {
      this.loadevent = true;
      let self = this;
      console.log("id:", this._decode(this.id));
      Restful.subscribers.available
        .availableDays(this._decode(this.id))
        .then((response) => {
          console.log(response.data);
          let m = response.data.split(",");
          // console.log("m:", m);
          this.arrayEvents = m;
          this.loadevent = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loadevent = false;
        });
    },
    pickDate(e) {
      console.log("e:", e);
      this.loading = true;
      this.carSize = 6;
      this.detail = true;
      let mydate = Date.parse(e);
      let subscriberid = this._decode(this.id);
      let data = { mydate, subscriberid };
      console.log(data);
      this.theEvent(data);

      Restful.subscribers.available
        .scheduleTime(data)
        .then((response) => {
          if (response.status === 200) {
            console.log("data: ", response);
            this.disregion = false;
            this.scheduleList = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    theEvent(data) {
      console.log("date:", data);
      let self = this;
      Restful.subscribers.available
        .availtimeDetails(data)
        .then((response) => {
          if (response.status === 200) {
            console.log("data: ", response);
            this.scheduleDetail = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeBook(p) {
      this.$set(this.bookingDialog, p.id, p.state);
    },
    checkStatus(closed, done) {
      if (closed === true || done === true) {
        return true;
      } else {
        return false;
      }
    },
    submitBook(p) {
      console.log(p);
      this.snack.bar = true;
      Restful.medics.patient.request
        .create(p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.$set(this.bookingDialog, p.id, p.state);
    },
  },
};
</script>
